import React, { useState, useEffect } from 'react';
import { differenceInDays, format, subDays } from 'date-fns';
import _get from 'lodash/get';

import { client } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import { ResponsiveLine } from 'cccisd-nivo/line';
import Tooltip from 'cccisd-tooltip';

import IconFlag from 'cccisd-icons/flag';
import IconInfo from 'cccisd-icons/info';
import IconPencil from 'cccisd-icons/pencil7';
import IconPlus from 'cccisd-icons/plus-circle';
import IconStats from 'cccisd-icons/stats-dots';
import IconDownload from 'cccisd-icons/download4';

import dropdownQuery from './pm_dropdown.graphql';
import projectDataQuery from './projectData.graphql';
import csvProjectsQuery from './csvProjects.graphql';
import Callout from '../../components/Callout/index.js';
import { renderAlert } from '../dashboardHelpers.js';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const PMDashboard = () => {
    const username = Fortress.user.user.username;
    const [allProjects, setAllProjects] = useState([]);
    const [selectedProjectData, setSelectedProjectData] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [radioFilter, setRadioFilter] = useState('all');
    const [noProjects, setNoProjects] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const mostRecentProjectUpdate =
        Array.isArray(selectedProjectData?.projectUpdate) &&
        selectedProjectData.projectUpdate.length > 0 &&
        selectedProjectData.projectUpdate[0];

    const allProjectUpdates = Array.isArray(selectedProjectData?.projectUpdate)
        ? selectedProjectData.projectUpdate
        : [];

    const completedProjectData = allProjectUpdates.filter(update => update.completedDate);
    const previous7days = [0, 1, 2, 3, 4, 5, 6].map(i => {
        const timeStamp = subDays(new Date(), i);

        return format(timeStamp, 'YYYY-MM-DD');
    });

    const projectUpdateDevTags = mostRecentProjectUpdate?.devTags || {};
    const privateProjectInfoDevTags = selectedProjectData?.projectInfo?.devTags || {};

    // we moved some questions around, so values might come from one survey or the other
    const completionRate = privateProjectInfoDevTags?.completionRate || projectUpdateDevTags?.completionRate;
    const isProjectFlagged = privateProjectInfoDevTags?.isProjectFlagged || projectUpdateDevTags?.isProjectFlagged;
    const timelineRating = privateProjectInfoDevTags?.timelineRating || projectUpdateDevTags?.timelineRating;
    const timelineNotes = privateProjectInfoDevTags?.timelineNotes || projectUpdateDevTags?.timelineNotes;
    const scopeRating = privateProjectInfoDevTags?.scopeRating || projectUpdateDevTags?.scopeRating;
    const scopeNotes = privateProjectInfoDevTags?.scopeNotes || projectUpdateDevTags?.scopeNotes;
    const moraleRating = privateProjectInfoDevTags?.moraleRating || projectUpdateDevTags?.moraleRating;
    const moraleNotes = privateProjectInfoDevTags?.moraleNotes || projectUpdateDevTags?.moraleNotes;
    const generalNotes = privateProjectInfoDevTags?.generalNotes || projectUpdateDevTags?.generalNotes;
    const weeksOverDate = privateProjectInfoDevTags?.weeksOverDate || projectUpdateDevTags?.weeksOverDate;
    const burnRate = projectUpdateDevTags?.burnRate;

    async function getSelectedProjectData() {
        if (!selectedProject) {
            return;
        }

        const resp = await client.query({
            query: projectDataQuery,
            variables: { metricsPawnId: selectedProject.descendantRoles.metricspawn.pawn.pawnId },
            fetchPolicy: 'network-only',
        });

        setSelectedProjectData(resp.data.roles.metricspawn);
    }

    async function getProjectList() {
        let filter = null;
        if (radioFilter === 'mine') {
            filter = {
                eq: {
                    field: 'descendantRoles.metricspawn.privateProjectInfo.devTags.projectManager',
                    string: Fortress.user.acting.user.username,
                },
            };
        }

        const data = await client.query({ query: dropdownQuery, variables: { filter }, fetchPolicy: 'network-only' });

        if (radioFilter === 'flagged') {
            const projects = data.data.groups.projectList;

            if (projects.length > 0) {
                const flaggedProjects = projects.filter(
                    project =>
                        (project?.descendantRoles?.metricspawn?.privateProjectInfo?.devTags?.isProjectFlagged ||
                            project?.descendantRoles?.metricspawn?.projectUpdate?.devTags?.isProjectFlagged) ===
                        'checked'
                );

                if (flaggedProjects.length === 0) {
                    return setNoProjects(true);
                }

                setAllProjects(flaggedProjects);
                setSelectedProject(flaggedProjects[0]);
            }
        }

        if (data.data.groups.projectList.length === 0 || allProjects.length === 0) {
            setNoProjects(true);
        }

        if (data.data.groups.projectList.length > 0 && radioFilter !== 'flagged') {
            setAllProjects(data.data.groups.projectList);
            const defaultProject = data.data.groups.projectList[0];
            setSelectedProject(defaultProject);
            setNoProjects(false);
        }
    }
    useEffect(() => {
        getProjectList();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            getSelectedProjectData();
        }
    }, [selectedProject]);

    useEffect(() => {
        getProjectList();
    }, [radioFilter]);

    useEffect(() => {
        const completedInPrevious7Days = completedProjectData.filter(value =>
            previous7days.includes(value.completedDate)
        );

        if (completedInPrevious7Days.length > 0 && !editMode) {
            setEditMode(true);
        } else if (completedInPrevious7Days.length <= 0 && editMode) {
            setEditMode(false);
        }
    }, [editMode, completedProjectData]);

    const renderProjectModals = () => {
        return (
            <div className={style.surveyButtons}>
                <Modal
                    trigger={
                        <button type="button" className="btn btn-secondary">
                            <IconPencil spaceRight />
                            Public Project Info
                        </button>
                    }
                    title={
                        <span>
                            Public Project Info: <b>{selectedProject.group.label}</b>
                        </span>
                    }
                    size="large"
                >
                    <DeploymentPlayer
                        deploymentHandle="app_info"
                        disableLayout
                        onComplete={() => getSelectedProjectData()}
                        pawnId={selectedProject.descendantRoles.metricspawn.pawn.pawnId}
                        pawnHash={selectedProject.descendantRoles.metricspawn.pawn.pawnHash}
                        flowProps={{
                            defaultValueVariables: {
                                projectDescription:
                                    selectedProject?.publicProjectInfo?.devTags?.projectDescription ||
                                    privateProjectInfoDevTags?.projectDescription,
                            },
                        }}
                    />
                </Modal>
                <Modal
                    trigger={
                        <button type="button" className="btn btn-secondary">
                            <IconPlus spaceRight />
                            Private Project Info
                        </button>
                    }
                    title={
                        <span>
                            Private Project Info: <b>{selectedProject.group.label}</b>
                        </span>
                    }
                    size="large"
                >
                    <DeploymentPlayer
                        deploymentHandle="project_info"
                        disableLayout
                        onComplete={() => getSelectedProjectData()}
                        pawnId={selectedProject.descendantRoles.metricspawn.pawn.pawnId}
                        pawnHash={selectedProject.descendantRoles.metricspawn.pawn.pawnHash}
                        flowProps={{
                            defaultValueVariables: {
                                projectName: selectedProject.group.label,
                                projectManager: username,
                                startDate: {
                                    date: format(new Date(), 'YYYY-MM-DD'),
                                    startedAt: selectedProject.descendantRoles.metricspawn.startedAt,
                                },
                                completionRate,
                                isProjectFlagged: isProjectFlagged === 'checked',
                                timelineRating,
                                timelineNotes,
                                scopeRating,
                                scopeNotes,
                                moraleRating,
                                moraleNotes,
                                generalNotes: generalNotes || '',
                                weeksOverDate,
                            },
                        }}
                    />
                </Modal>
                <Modal
                    trigger={
                        <button type="button" className="btn btn-primary me-0">
                            <IconStats spaceRight />
                            Burn Rate
                        </button>
                    }
                    title={
                        editMode
                            ? `Editing ${selectedProject.group.label} Update from ${mostRecentProjectUpdate.completed}`
                            : selectedProject.group.label + ' - Update'
                    }
                    size="large"
                    headerStyle={editMode ? { backgroundColor: '#449D44', color: '#fff' } : {}}
                >
                    <DeploymentPlayer
                        deploymentHandle="project_update"
                        disableLayout
                        deploymentId={editMode ? mostRecentProjectUpdate.deploymentId : null}
                        onComplete={() => getSelectedProjectData()}
                        pawnId={selectedProject.descendantRoles.metricspawn.pawn.pawnId}
                        pawnHash={selectedProject.descendantRoles.metricspawn.pawn.pawnHash}
                        flowProps={{
                            defaultValueVariables: {
                                burnRate,
                            },
                        }}
                    />
                </Modal>
            </div>
        );
    };

    const renderProjectDropdown = () => (
        <>
            <form className="d-flex justify-content-between">
                <div className="mb-3">
                    <div className={style.radios}>
                        <label className="form-label" htmlFor="site">
                            Project
                            <select
                                value={selectedProject.group.groupId}
                                className="form-select me-3"
                                id="site"
                                onChange={e => {
                                    let desiredProject = allProjects.find(project => {
                                        return Number(project.group.groupId) === Number(e.target.value);
                                    });
                                    setSelectedProject(desiredProject);
                                }}
                            >
                                {allProjects.map(project => (
                                    <option value={project.group.groupId} key={project.group.groupId}>
                                        {project.group.label}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label className="form-label" htmlFor="all">
                            <input
                                type="radio"
                                name="all"
                                id="all"
                                className="me-1"
                                value="all"
                                checked={radioFilter === 'all'}
                                onChange={e => setRadioFilter('all')}
                            />
                            All Projects
                        </label>
                        <label className="form-label" htmlFor="mine">
                            <input
                                type="radio"
                                name="mine"
                                id="mine"
                                className="me-1"
                                value="mine"
                                checked={radioFilter === 'mine'}
                                onChange={e => setRadioFilter('mine')}
                            />
                            My Projects
                        </label>
                        <label className="form-label" htmlFor="flagged">
                            <input
                                type="radio"
                                name="flagged"
                                value="flagged"
                                id="flagged"
                                className="me-1"
                                checked={radioFilter === 'flagged'}
                                onChange={e => setRadioFilter('flagged')}
                            />
                            Flagged Projects
                        </label>
                    </div>
                </div>
                <span style={{ alignSelf: 'center' }} className="pt-3">
                    {renderCsvDownload()}
                </span>
            </form>
            <hr style={{ borderColor: '#e17d26' }} />
        </>
    );

    function getBackgroundColor(val) {
        if (Number.isNaN(Number(val))) {
            return '#cc3232';
        }
        if (Number(val) <= 1) {
            return '#cc3232';
        }
        if (Number(val) <= 2) {
            return '#e7b416';
        }
        return '#99c140';
    }

    function renderCsvDownload() {
        return (
            <button
                onClick={async () => {
                    const csvProjectsResponse = await client.query({
                        query: csvProjectsQuery,
                        fetchPolicy: 'network-only',
                    });

                    let rows = [
                        'Project,Project Manager,Completion Rate,Burn Rate,Timeline Rating,Timeline Notes,Scope Rating,Scope Notes,Morale Rating,Morale Notes',
                    ];
                    csvProjectsResponse.data.groups.projectList.forEach(project => {
                        const csvPrivateInfoDevTags =
                            project?.descendantRoles?.metricspawn?.privateProjectInfo?.devTags || {};
                        const csvProjectUpdateDevTags =
                            project?.descendantRoles?.metricspawn?.projectUpdate?.devTags || {};

                        const label = (project?.group?.label || '').replace(/"/g, "'");
                        const pm = csvPrivateInfoDevTags?.projectManager || '';
                        const csvCompletionRate =
                            csvPrivateInfoDevTags?.completionRate || csvProjectUpdateDevTags?.completionRate || '';
                        const csvBurnRate = csvProjectUpdateDevTags?.burnRate || '';
                        const csvScopeRating =
                            csvPrivateInfoDevTags?.scopeRating || csvProjectUpdateDevTags?.scopeRating || '';
                        const csvScopeNotes = (
                            csvPrivateInfoDevTags?.scopeNotes ||
                            csvProjectUpdateDevTags?.scopeNotes ||
                            ''
                        ).replace(/"/g, "'");
                        const csvTimelineRating =
                            csvPrivateInfoDevTags?.timelineRating || csvProjectUpdateDevTags?.timelineRating || '';
                        const csvTimelineNotes = (
                            csvPrivateInfoDevTags?.timelineNotes ||
                            csvProjectUpdateDevTags?.timelineNotes ||
                            ''
                        ).replace(/"/g, "'");
                        const csvMoraleRating =
                            csvPrivateInfoDevTags?.moraleRating || csvProjectUpdateDevTags?.moraleRating || '';
                        const csvMoraleNotes = (
                            csvPrivateInfoDevTags?.moraleNotes ||
                            csvProjectUpdateDevTags?.moraleNotes ||
                            ''
                        ).replace(/"/g, "'");

                        rows.push(
                            `"${label}","${pm}","${csvCompletionRate}","${csvBurnRate}","${csvTimelineRating}","${csvTimelineNotes}","${csvScopeRating}","${csvScopeNotes}","${csvMoraleRating}","${csvMoraleNotes}"`
                        );
                    });

                    const csv = rows.join('\n');
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'Project_statuses_' + format(new Date(), 'MM-DD-YYYY');
                    link.click();
                }}
                type="button"
                className="btn btn-link"
            >
                <IconDownload /> CSV of All Projects
            </button>
        );
    }

    const renderProjectInfo = () => {
        if (!selectedProject?.descendantRoles?.metricspawn?.pawn?.pawnId) {
            return <span />;
        }

        if (!selectedProjectData) {
            return <Loader loading type="inline" />;
        }

        const projectLength = differenceInDays(
            selectedProjectData.projectInfo?.devTags?.projectedEndDate || '',
            selectedProjectData.projectInfo?.devTags?.startDate || ''
        );

        const isFlagged = isProjectFlagged === 'checked';

        const formatBudget = () => {
            const devTagBudget = _get(selectedProjectData, 'projectInfo.devTags.budget') || '-';

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            });

            return devTagBudget !== '-' ? formatter.format(devTagBudget) : '-';
        };

        const stats = [
            {
                id: 'Timeline',
                notes: timelineNotes || '-',
                rating: (
                    <div
                        className={style.snapshotBubble}
                        style={{
                            backgroundColor: getBackgroundColor(timelineRating),
                        }}
                    />
                ),
            },
            {
                id: 'Scope',
                notes: scopeNotes || '-',
                rating: (
                    <div
                        className={style.snapshotBubble}
                        style={{
                            backgroundColor: getBackgroundColor(scopeRating),
                        }}
                    />
                ),
            },
            {
                id: 'Morale',
                notes: moraleNotes || '-',
                rating: (
                    <div
                        className={style.snapshotBubble}
                        style={{
                            backgroundColor: getBackgroundColor(moraleRating),
                        }}
                    />
                ),
            },
        ];

        const projectDescription =
            selectedProjectData?.publicProjectInfo?.projectDescription || privateProjectInfoDevTags?.projectDescription;

        return (
            <div>
                <div className={style.projectDetails}>
                    <h2>
                        {selectedProject.group.label}{' '}
                        {isFlagged && (
                            <span style={{ color: '#cc3232' }}>
                                <IconFlag />
                            </span>
                        )}
                    </h2>
                    {renderProjectModals()}
                </div>
                {selectedProjectData?.projectInfo?.lastVisitedDate ? (
                    <div className={style.projectSnapshot}>
                        <div className={` ${style.details}`}>
                            <div className="mb-2">
                                <div className={style.projectDescription}>
                                    <b>Start Date</b>{' '}
                                    {projectDescription && (
                                        <Tooltip title={projectDescription}>
                                            <IconInfo spaceRight />
                                        </Tooltip>
                                    )}
                                </div>
                                <div>{_get(selectedProjectData, 'projectInfo.startDate') || '-'}</div>
                            </div>
                            <div className="mb-2">
                                <b>Projected End Date</b>
                                <div>{_get(selectedProjectData, 'projectInfo.projectedEndDate') || '-'}</div>
                            </div>
                            <div className="mb-2">
                                <b>Contractual End Date</b>
                                <div>{_get(selectedProjectData, 'projectInfo.contractualEndDate') || '-'}</div>
                            </div>
                            <div style={{ overflowWrap: 'break-word' }} className="mb-2">
                                <b>Project Manager</b>
                                <div>{_get(selectedProjectData, 'projectInfo.devTags.projectManager') || '-'}</div>
                            </div>
                            <div>
                                <b>Completion Rate</b>
                                <div>{completionRate}%</div>
                            </div>
                        </div>
                        <div className={style.callouts}>
                            {stats.map(stat => {
                                return <Callout key={stat.id} id={stat.id} notes={stat.notes} rating={stat.rating} />;
                            })}
                        </div>
                    </div>
                ) : (
                    renderAlert(' Not enough Project Info to show - please complete both surveys.')
                )}
                {mostRecentProjectUpdate &&
                selectedProjectData?.projectInfo?.completedDate &&
                selectedProjectData?.projectInfo?.devTags?.projectedEndDate ? (
                    <>
                        <div className={style.graphContainer}>
                            <div>
                                <h3>Operating Budget</h3>
                                <h4>{formatBudget()}</h4>
                            </div>
                            <ResponsiveLine
                                animate
                                axisBottom={{
                                    format: projectLength < 365 ? '%b %d' : '%b %Y',
                                    legend: 'Project Timeline',
                                    legendOffset: 50,
                                    legendPosition: 'middle',
                                    tickRotation: -30,
                                }}
                                axisLeft={{
                                    legend: 'Burn Rate (%)',
                                    legendOffset: -50,
                                    legendPosition: 'middle',
                                }}
                                curve="monotoneX"
                                data={[
                                    {
                                        id: 'expected',
                                        data: [
                                            {
                                                x: selectedProjectData.projectInfo?.devTags?.startDate || '',
                                                y: 0,
                                            },
                                            {
                                                x: selectedProjectData.projectInfo?.devTags?.projectedEndDate || '',
                                                y: 100,
                                            },
                                        ],
                                    },
                                    {
                                        id: 'actual',
                                        data: completedProjectData.map(d => {
                                            return {
                                                x: d.completedDate,
                                                y: Number(d.devTags.burnRate),
                                            };
                                        }),
                                    },
                                ]}
                                enablePointLabel
                                height={400}
                                margin={{
                                    bottom: 60,
                                    left: 110,
                                    right: 100,
                                    top: 20,
                                }}
                                markers={[
                                    {
                                        axis: 'y',
                                        value: '100',
                                        lineStyle: {
                                            stroke: 'green',
                                            strokeWidth: 1,
                                        },
                                    },
                                ]}
                                pointBorderColor={{
                                    from: 'color',
                                    modifiers: [['darker', 0.3]],
                                }}
                                pointBorderWidth={1}
                                pointSize={16}
                                useMesh
                                width={900}
                                xFormat="time:%m/%d/%Y"
                                yFormat={d => `Burn Rate ${d}%`}
                                xScale={{
                                    format: '%Y-%m-%d',
                                    precision: 'day',
                                    type: 'time',
                                    useUTC: false,
                                }}
                                yScale={{
                                    type: 'linear',
                                }}
                                colors={{ scheme: 'paired' }}
                            />
                        </div>
                    </>
                ) : (
                    renderAlert('Please add required fields to Project Info.')
                )}
                <div className={style.generalBox}>
                    <div className={style.generalHeader}>
                        <h3>General Notes</h3>
                        <div style={{ color: '#333' }}>{selectedProjectData?.projectInfo?.lastVisitedDate || '-'}</div>
                    </div>
                    <hr />
                    {generalNotes || '-'}
                </div>
            </div>
        );
    };

    if (!selectedProject?.group?.groupId) {
        return renderAlert('No matching projects found.');
    }

    return (
        <>
            {renderProjectDropdown()}
            {allProjects.length > 0 && !noProjects ? renderProjectInfo() : renderAlert('No matching projects found.')}
        </>
    );
};

export default PMDashboard;
