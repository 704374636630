import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'cccisd-footer';
import style from './style.css';
import AppHeader from '../../components/Header';

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: true,
    };

    render() {
        return (
            <div className={style.wrapper}>
                <AppHeader />
                <div className={style.body}>
                    <div className={this.props.className}>{this.props.children}</div>
                </div>

                <div className={style.footer}>
                    <Footer className={this.props.className} />
                </div>
                <iframe
                    src="https://www.quest4data.com/3cstaff/"
                    style={{ height: '1px', width: '1px', position: 'absolute', left: '-5000px' }}
                    title="3C Staff Cookie"
                    aria-hidden="true"
                    tabIndex="-1"
                />
            </div>
        );
    }
}
