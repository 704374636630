import React, { useState } from 'react';
import axios from 'cccisd-axios';
import IconArchive from 'cccisd-icons/box-add';
import IconUnarchive from 'cccisd-icons/box-remove';
import Notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';

const Boilerplate = window.cccisd.boilerplate;

const ToggleActiveButton = ({ row, loadData, isRowSelected }) => {
    const [isLoading, setIsLoading] = useState(false);

    const isActive = row['fields.isActive'];

    async function handleClick() {
        if (isRowSelected) {
            return;
        }

        setIsLoading(true);
        const resp = await axios.put(Boilerplate.route('api.monitoring.projects.toggleIsActive'), {
            groupId: row['group.groupId'],
            randomHash: row['group.randomHash'],
        });

        const projectName = row['group.label'];
        if (resp?.data?.status === 'success') {
            Notification({ message: `${projectName} was set to ${isActive ? 'inactive' : 'active'}`, type: 'success' });
        } else {
            Notification({ message: `Failed to edit ${projectName}`, type: 'danger' });
        }

        setIsLoading(false);
        loadData();
    }

    return (
        <Tooltip title={isActive ? 'Make Inactive' : 'Make Active'}>
            <button
                className={'btn btn-xs ' + (isActive ? 'btn-primary' : 'btn-info')}
                type="button"
                onClick={handleClick}
                disabled={isLoading || isRowSelected}
            >
                {isActive ? <IconArchive /> : <IconUnarchive />}
            </button>
        </Tooltip>
    );
};

export default ToggleActiveButton;
